import React from 'react';
import { List, Datagrid, BooleanField , TextField, EditButton, ReferenceArrayField, SingleFieldList, ChipField ,
    SearchInput, Filter, ReferenceInput ,AutocompleteInput, TextInput } from 'react-admin';
import UserListActions from './UserListActions';
import UserListBulkActions from './UserListBulkActions';
import { Pagination } from '../../layout'
import EmptyImport from '../../layout/EmptyImport';
 
const UserFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        {/*<NullableBooleanInput source="isAdmin" />*/}
        <ReferenceInput label="Group" source="groupIds" reference="groups">
            <AutocompleteInput  optionText="name" />
        </ReferenceInput >
    </Filter>
);

export const UserList = (props: any) => (
    <List {...props} filters={<UserFilter />} sort={{ field: 'name', order: 'ASC' }} empty={<EmptyImport basePath={props.basePath} />} actions={<UserListActions />} /*bulkActionButtons={<UserListBulkActions />}*/ pagination={<Pagination />} perPage={50} >
        <Datagrid optimized rowClick="edit">
            <TextField source="name" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <BooleanField source="isAdmin" sortable={false} />
            <ReferenceArrayField label="Groups" reference="groups" source="groupIds" sortable={false}>
                <SingleFieldList >
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton />
        </Datagrid>
    </List>
);

export default UserList;